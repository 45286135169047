.search-result {
  .main-result,
  .sub-result {
    display: flex;
    cursor: pointer;

    .thumbnail-container {
      flex: none;
      margin-right: 17px;

      .thumbnail {
        width: 246px;
        height: 138px;
      }
    }

    .info-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      text-align: left;

      .description {
        max-height: 100px;
        overflow: scroll;
      }

    }
  }

  .sub-results {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 40px;

    .ant-list-item {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .sub-result {
    .thumbnail-container {
      .thumbnail {
        width: 150px;
        height: 80px;
      }
    }
  }

  .collapser {
    margin-top: 10px;

    .ant-collapse-content-box {
      padding: 0;
    }
  }
}

@media screen and (max-width:576px) {
  .search-result {
    .main-result,
    .sub-result {
      display: block;

      .info-container {
        .description {
          max-height: 80px;
        }

      }
    }
  }
}
