.statistic-menu {
  text-align: left;
  //&.ant-menu-inline-collapsed {
  //   width: 40px;
  //
  //  .ant-menu-submenu {
  //    .ant-menu-submenu-title {
  //      padding: 0 5px !important;
  //    }
  //  }
  //}
}
