.home {
  .main-layout {
    min-height: 100vh;
  }

  .app-header {
    background: #f0f2f5;
  }

  .logo-container {
    height: 65px;
    background: rgba(255, 255, 255, 0.2);

    .logo {
      width: 100%;
      height: 100%;
    }
  }
}
