.app {
  text-align: center;
}
.app-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-container {
  //background-color: #282c34;
  min-height: 100vh;
}
